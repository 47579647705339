.project {
    position: absolute;
    background-color: #ffffff;
    top: 61px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background-color: #ffffff;
    width: calc(100% - 62px);
    max-width: 930px;
    padding: 20px;
    border-radius: 20px;
    z-index: 1;
    color: #ffffff;
    background: rgb(70,70,75);

    &.auxly {
        background: linear-gradient(80deg, rgba(70,70,75,0.8136029411764706) 27%, rgba(226,56,65,0.7136029411764706) 50%, rgba(70,70,75,0.8136029411764706) 73%);
    }
    &.dosecann {
        background: linear-gradient(80deg, rgba(70,70,75,0.8134348739495799) 27%, rgba(110,30,230,0.7136029411764706) 50%, rgba(70,70,75,0.8136029411764706) 73%);
    }
    &.kolab-project {
        background: linear-gradient(80deg, rgba(70,70,75,0.8134348739495799) 27%, rgba(19,18,18,0.7136029411764706) 50%, rgba(70,70,75,0.8136029411764706) 73%);    
    }
    &.national-sparks {
        background: linear-gradient(80deg, rgba(70,70,75,0.8134348739495799) 27%, rgba(203,138,43,0.7136029411764706) 50%, rgba(70,70,75,0.8136029411764706) 73%);
    }
    &.robinsons {
        background: linear-gradient(80deg, rgba(70,70,75,0.8134348739495799) 27%, rgba(52,159,58,0.7136029411764706) 50%, rgba(70,70,75,0.8136029411764706) 73%);
    }
    &.blue-tile-lounge {
        background: linear-gradient(80deg, rgba(70,70,75,0.8136029411764706) 27%, rgba(54,39,185,0.7136029411764706) 50%, rgba(70,70,75,0.8108018207282913) 73%);
    }
    &.string-systems {
        background: linear-gradient(80deg, rgba(70,70,75,0.8136029411764706) 27%, rgba(48, 102, 242,0.7136029411764706) 50%, rgba(70,70,75,0.8108018207282913) 73%);
    }
    &.disauthority {
        background: linear-gradient(80deg, rgba(70,70,75,0.8136029411764706) 27%, rgba(240, 139, 97, 0.7136029411764706) 50%, rgba(70,70,75,0.8108018207282913) 73%);
    }

    @media(max-width: 700px) {
        width: 100%;   
    }

    .project-title {
        display: flex;
        justify-content: space-between;
        align-items: center;   
        text-transform: uppercase; 
        padding-bottom: 1.2em;
        background: transparent;

        * {
            background: transparent;
        }
    }

    .project-details {
        display: flex;
        flex-direction: row-reverse;
        gap: 30px;
        background: transparent;

        * {
            background: transparent;
        }

        @media(max-width: 700px) {
            flex-direction: column-reverse;
            gap: 0;
        }

        .detail-item {
            display: flex;
            flex-direction: row;
            padding-bottom: 1em;

            h4 {
                min-width: 100px;
                text-transform: uppercase;
            }
        }
    }

    .project-images {
        padding-top: 1em;
        background: transparent;

        * {
            background: transparent;
        }

        .project-image {
            max-width: 100%;
            width: 100%;
            padding: 0.5em 0;
        }
        img {
            max-width: 100%;
            width: 100%;
        }
    }
}

.background-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);

    &::before {
        content: "";
        background-color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }
}