.projects-index {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media(max-width: 670px) {
        flex-direction: row-reverse;
    }

    .project-hover-image {
        width: 50%;
        max-width: 100%;
        position: relative;

        img {
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }

        @media(max-width: 670px) {
            display: none;
        }
    }

    .projects-list {
        width: 50%;
        max-width: 100%;

        @media(max-width: 670px) {
            width: 100%;
        }

        .project-title {
            text-transform: lowercase;
            border-bottom: 1px solid;
        }

        .project-link {
            padding: 7px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            overflow-x: hidden;

            .arrow-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                transform: translateX(-31px);
            }

            .arrow-right {
                 margin-top: 3px;
                 margin-right: 10px;

                 svg {
                    fill: #ffffff;
                }
            }
            .chevron-right {
                margin-top: 3px;
                margin-left: 10px;

                svg {
                    fill: #ffffff;
                }
           }
        }
    }
}