.site-nav-main {
    width: 100%;
    max-width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 20;

    .mobile-nav-header {
        display: flex;
        padding: 21px 31px;
        text-transform: uppercase;
        background-color: transparent;

        @media(max-width: 768px) {
            padding: 21px 15px;
        }

        a {
            &:hover,
            &.active {
                text-underline-offset: 4px;
                text-decoration: underline;
            }
        }
    }

    .hamburger {
        border: 0;
        position: absolute;
        right: 30px;
        top: 15px;
        cursor: pointer;
        display: block;
        background-color: transparent;

        @media(max-width: 768px) {
            right: 15px;
        }

        .bar {
           display: block;
           width: 22px;
           height: 2px;
           margin: 5px auto;
           background: #ffffff;
        }
    }

    .main-nav-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 21px 31px;
        padding-top: 84px;
        text-transform: uppercase;

        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        transform: translateY(-200%);
        -webkit-box-shadow: 0px 17px 23px -21px rgba(0,0,0,0.39);
        -moz-box-shadow: 0px 17px 23px -21px rgba(0,0,0,0.39);
        box-shadow: 0px 17px 23px -21px rgba(0,0,0,0.39);
        

        -webkit-transition: all 450ms ease;
        -moz-transition: all 450ms ease;
        -ms-transition: all 450ms ease;
        -o-transition: all 450ms ease;
        transition: all 450ms ease;
        background: transparent;

        * {
            background: transparent;
        }

        &::after {
            content: "";
            background-color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.4;
        }

        &.active {
            backdrop-filter: blur(10px);
            transform: translateY(0%);
        }

        .main-nav-link {
            display: inline-block;
            padding-bottom: 1em;

            a {
                &:hover,
                &.active {
                    text-underline-offset: 4px;
                    text-decoration: underline;
                }
            }
        }
    }
}

.auxly, 
.kolab-project,
.dosecann,
.national-sparks,
.robinsons,
.blue-tile-lounge {
    .hamburger {
        .bar {
            background: #000000;
        }
    }
    .mobile-nav-header,
    .main-nav-links {
        color: #000000;
    }
}