@import './_variables';
@import './_navigation';

html, * {
    box-sizing: border-box;   
    margin: 0;
    padding: 0; 
    // background: 
    // repeating-radial-gradient(#242526 0 0.0001%,#7e8083 0 0.0002%) 50% 0/2500px 2500px,
    // repeating-conic-gradient(#242526 0 0.0001%,#7e8083  0 0.0002%) 60% 60%/2500px 2500px;
    // background-blend-mode: difference;
    // animation: static .2s infinite alternate;
    // -webkit-animation: static .2s infinite alternate;
    // -moz-animation: static .2s infinite alternate;
    // -ms-animation: static .2s infinite alternate;
    // -o-animation: static .2s infinite alternate;
} 

// @keyframes static {
//     100% {background-position: 50% 0, 60% 50%}
// }

#root {
    min-height: 100vh;
    position: relative;
}

.noise-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 101vw;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: radial-gradient(ellipse at center, rgba(70,70,75,0.4) 0%,rgba(0,0,0,0.95) 100%);
    }
}

@keyframes tv-static {
    from {
      background-size: 200% 100%;
    }
  
    to {
      background-size: 100% 200%;
    }
  }

.noise {
    position: absolute;
    top: -500px;
    right: -500px;
    bottom: -500px;
    left: -500px;
    background: url(https://www.dropbox.com/s/h7ab1c82ctzy83n/noise.png?raw=1) 0 0;
    background-size: 320px 320px;
    opacity: 1;
    animation: noise 1s steps(8,end) infinite both;
}

@keyframes noise {
    0% {
      transform: translateX(0px,0px); }
    10% {
      transform: translate(-100px, 100px);
    }
    20% {
      transform: translate(150px, -100px);
    }
    30% {
      transform: translate(-100px,100px);
    }
    40% {
      transform: translate(100px, -150px);
    }
    50% {
      transform: translate(-100px, 200px);
    }
    60% {
      transform: translate(-200px, -100px);
    }
    70% {
      transform: translateY(50px, 100px);
    }
    80% {
      transform: translate(100px, -150px);
    }
    90% {
      transform: translate(0px, 200px);
    }
    100% {
      transform: translate(-100px, 100px);
    }
}

.main {
    min-height: 100vh;
    position: relative;
    display: block;
    margin: 0 auto;
    width: calc(100% - 62px);
    max-width: 813px;
    transition: .2s ease transform;
    color: #ffffff;
}

@media(max-width: 768px) {
    .main {
        width: calc(100% - 31px);
    }
}

ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;

    li {
        display: block;
    }
}

a {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    border-width: 0;
    background-color: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-decoration: none;
}

.page-title {
    border-bottom: 1px solid;
    h1 {
        text-transform: uppercase; 
        padding-bottom: 10px;
    }
}

.visuallyhidden {
    display: none;
    opacity: 0;
    height: 0;
}

.x-close {
    cursor: pointer;
    position: relative;
    width: 32px;
    height: 32px;

    .stroke {
        position: absolute;
        content: ' ';
        height: 33px;
        left: 15px;
        width: 1px;
        background-color: #ffffff;

        &:first-child {
            transform: rotate(45deg);
        }
        &:last-child {
            transform: rotate(-45deg);
        }
    }
}

.strike-through {
  text-decoration: line-through;
  pointer-events: none;
}