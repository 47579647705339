.about {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    .about-text,
    .about-image {
        width: 50%;
        max-width: 100%;

        @media(max-width: 670px) {
            width: 100%;
        }
    }

    .about-description {

        h2 {
            font-size: 18px;
            padding-top: 1em;
        }
    }
}