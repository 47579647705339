#error-page {
    display: block;
    width: 100%;
    max-width: 100%;
}

.error-info {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}